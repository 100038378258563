<template>
	<!-- GNB는 PC, 모바일 두 종류 있으니 둘 다 확인 (✅)-->
	<header>
		<div class="header">
			<div class="header__wrap">
				<div class="left-area">
					<nuxt-link to="/">
						<img src="@/assets/images/header-logo.svg" alt="header-logo" />
					</nuxt-link>
				</div>
				<div class="only-pc">
					<div class="right-area">
						<div v-if="authStore.loggedIn" class="flex items-center justify-center align-items-baseline">
							<PButton outlined @click="moveToNotice" :class="{ active: route.path === '/notice' }" class="notice"
								>공지사항</PButton
							>
							<PButton outlined @click="moveToMyPage" :class="{ active: route.path === '/my' }" class="my"
								>마이페이지</PButton
							>
						</div>
						<div v-else class="flex gap-3 items-center justify-center align-items-baseline">
							<PButton outlined @click="moveToLogin" :class="{ active: route.path === '/auth/login' }" class="login"
								>로그인</PButton
							>
							<PButton outlined @click="moveToSignup" :class="{ active: route.path === '/auth/signup' }" class="signup"
								>가입하기</PButton
							>
						</div>
					</div>
				</div>
				<div class="only-mobile">
					<div class="right-area">
						<div v-if="authStore.loggedIn" class="flex gap-3 items-center justify-center align-items-baseline">
							<nuxt-link to="/notice">
								<img
									src="@/assets/images/icon/icon-24-gnb-notice.svg"
									style="height: 24px; width: 24px; vertical-align: middle"
									alt="공지사항"
								/>
							</nuxt-link>
							<nuxt-link @click="moveTo">
								<img
									src="@/assets/images/icon/icon-24-gnb-my.svg"
									style="height: 24px; width: 24px; vertical-align: middle"
									alt="마이 페이지"
								/>
							</nuxt-link>
						</div>
						<div v-else class="flex gap-3 items-center justify-center align-items-baseline">
							<nuxt-link to="/auth/login">
								<img
									src="@/assets/images/icon/icon-24-gnb-login.svg"
									style="height: 24px; width: 24px; vertical-align: middle"
									alt="로그인"
								/>
							</nuxt-link>
							<nuxt-link to="/auth/signup">
								<img
									src="@/assets/images/icon/icon-24-gnb-signup.svg"
									style="height: 24px; width: 24px; vertical-align: middle"
									alt="회원 가입"
								/>
							</nuxt-link>
						</div>

						<button type="button" @click="menuActive = true">
							<img src="@/assets/images/icon/icon-24-gnb-hamburger.svg" alt="" />
						</button>
					</div>
				</div>
			</div>
		</div>

		<!--✅ GNB : PC-->
		<div class="sub-header" :class="[{ hover: dropMenuOn }]" @mouseleave="subHeaderLeave" @click="resetHover">
			<div class="sub-header__wrap">
				<!-- 1depth -->
				<div class="center-area">
					<nuxt-link
						v-for="menuItem in menuList"
						:key="menuItem.menuId"
						:to="getMenuOrTemplateLink(menuItem)"
						@mouseover="handleMouseOver(menuItem)"
						@mouseleave="handleMouseLeave(menuItem)"
						@touchstart="handleMouseOver(menuItem)"
						:class="{ hover: activeHoverItem?.menuId === menuItem.menuId }"
					>
						{{ menuItem.menuName }}
					</nuxt-link>
				</div>

				<!-- 오른쪽 메뉴 -->
				<div class="right-area">
					<PButton
						outlined
						@click="
							() => {
								go(
									authStore.loggedIn && userStore.subscribeYn === 'Y'
										? RoutesConstants.SERVICE_PLAN
										: RoutesConstants.TRIAL_INDEX
								)
								resetHover()
							}
						"
						>{{ servicePlanLabel }}</PButton
					>
					<PButton
						primary
						@click="
							() => {
								go(RoutesConstants.INQUIRY_WRITE)
								resetHover()
							}
						"
						>문의하기</PButton
					>
				</div>
			</div>

			<!-- 2depth -->
			<div class="sub-header__wrap drop-menu__wrap">
				<div class="center-area">
					<ul
						v-for="(menuItem, index) in menuList"
						:key="menuItem.menuId"
						class="drop-menu"
						@mouseover="handleMouseOver(menuItem)"
						@mouseleave="handleMouseLeave(menuItem)"
						:class="{ hover: activeHoverItem?.menuId === menuItem.menuId }"
					>
						<li v-for="subItem in menuItem.subMenuList" :key="subItem.menuId">
							<nuxt-link :to="getMenuOrTemplateLink(subItem)">{{ subItem.menuName }}</nuxt-link>
						</li>
					</ul>
				</div>
			</div>
		</div>

		<div class="mobile-menu" :class="{ active: menuActive }">
			<div class="mobile-menu__header">
				<div class="left-area">
					<nuxt-link to="">
						<img src="@/assets/images/header-logo.svg" alt="header-logo" />
					</nuxt-link>
				</div>

				<div class="right-area" @click="menuActive = false">
					<div class="flex gap-3 items-center justify-center align-items-baseline">
						<template v-if="authStore.loggedIn">
							<nuxt-link to="/notice">
								<img
									src="@/assets/images/icon/icon-24-gnb-notice.svg"
									style="height: 24px; width: 24px; vertical-align: middle"
									alt="공지사항"
								/>
							</nuxt-link>
							<nuxt-link @click="moveTo">
								<img
									src="@/assets/images/icon/icon-24-gnb-my.svg"
									style="height: 24px; width: 24px; vertical-align: middle"
									alt="마이 페이지"
								/>
							</nuxt-link>
						</template>
						<template v-else>
							<nuxt-link to="/auth/login">
								<img
									src="@/assets/images/icon/icon-24-gnb-login.svg"
									style="height: 24px; width: 24px; vertical-align: middle"
									alt="로그인"
								/>
							</nuxt-link>
							<nuxt-link to="/auth/signup">
								<img
									src="@/assets/images/icon/icon-24-gnb-signup.svg"
									style="height: 24px; width: 24px; vertical-align: middle"
									alt="회원 가입"
								/>
							</nuxt-link>
						</template>
						<img
							src="@/assets/images/icon/icon-24-gnb-close.svg"
							style="height: 24px; width: 24px; vertical-align: middle"
							alt="닫기"
							@click="menuActive = false"
						/>
					</div>
				</div>
			</div>

			<!--✅ GNB : 모바일 -->
			<div class="mobile-menu__body">
				<Accordion class="accordion-basic">
					<AccordionTab v-for="menu in menuList" :key="menu.menuId" :pt="{ root: 'tab-feature' }">
						<template #header>
							<p @click="(event) => handleClickMobile1Depth(event, menu)">{{ menu.menuName }}</p>
						</template>
						<ul v-if="menu.subMenuList && menu.subMenuList.length > 0">
							<li v-for="subItem in menu.subMenuList" :key="subItem.menuId">
								<nuxt-link :to="getMenuOrTemplateLink(subItem)">
									<p @click="menuActive = false">
										{{ subItem.menuName }}
									</p>
								</nuxt-link>
							</li>
						</ul>
						<template v-if="menu.subMenuList && menu.subMenuList.length < 1" #headericon><div></div></template>
					</AccordionTab>
				</Accordion>
			</div>
			<div class="bottom-btns">
				<PButton
					class="w-full"
					:style="{ marginBottom: '8px' }"
					primary
					@click="
						() => {
							go(
								authStore.loggedIn && userStore.subscribeYn === 'Y'
									? RoutesConstants.SERVICE_PLAN
									: RoutesConstants.TRIAL_INDEX
							)
							menuActive = false
						}
					"
					>{{ servicePlanLabel }}</PButton
				>
				<p></p>
				<PButton
					class="w-full"
					:style="{ border: '1px solid var(--Neutral-Gray-400)' }"
					outlined
					@click="
						() => {
							go(RoutesConstants.INQUIRY_WRITE)
							menuActive = false
						}
					"
					>문의하기</PButton
				>
			</div>
		</div>
		<div class="dim" :class="{ active: menuActive }" @click="menuActive = false"></div>
	</header>
</template>

<script setup lang="ts">
import { ref } from 'vue'
import RoutesConstants from '~~/home/types/route'
import PButton from '~~/home/components/PButton.vue'
import Accordion from 'primevue/accordion'
import type { HeaderMenuList } from '~~/home/types/header/mainHeaderTypes'

import { useUserStore } from '~/stores/user'
import MenuService from '~~/home/services/home/MenuService'
import type { MenuItem } from '~~/home/types/home/MenuTypes'

const menuService = new MenuService()
const dropMenuOn = ref(false)

const authStore = useAuthStore()
const router = useRouter()
const route = useRoute()
const userStore = useUserStore()

const menuActive = ref(false)

const servicePlanLabel = computed(() => {
	if (authStore.loggedIn && userStore.subscribeYn === 'Y') {
		return '요금제 보기'
	}
	return '무료 체험'
})

const { data } = useAsyncData('getHeaderMenu', () => menuService.getMenuList())
const menuList = computed<HeaderMenuList>(() => data.value?.data?.menu || [])

// 템플릿일 경우 분기하기 위한 함수
const getMenuOrTemplateLink = (menuItem: MenuItem) => {
	if (menuItem.templateYn === 'Y') {
		if (menuItem.menuLink) {
			return menuItem.menuLink
		} else {
			return `/template/${menuItem.menuId}`
		}
	} else if (menuItem.menuLink) {
		return menuItem.menuLink
	}
	// 링크가 존재하지 않을 때
	return '/'
}
/**
 * ui 동작
 *  */
const activeHoverItem = ref<MenuItem | null>(null)
const handleMouseOver = (item: MenuItem) => {
	activeHoverItem.value = item
	dropMenuOn.value = (item?.subMenuList && item.subMenuList.length > 0) || false
}

const handleMouseLeave = (item: MenuItem) => {
	if (activeHoverItem.value === item) {
		activeHoverItem.value = null
	}
	dropMenuOn.value = false
}

const subHeaderLeave = () => {
	dropMenuOn.value = false
}

const resetHover = () => {
	activeHoverItem.value = null
	dropMenuOn.value = false
}

/**
 * 모바일
 */
const handleClickMobile1Depth = (e: MouseEvent, item: MenuItem) => {
	// 하위 메뉴가 없을 경우 메뉴 열지 않고 이동
	if (!item.subMenuList || item.subMenuList.length < 1) {
		e.stopPropagation()
		menuActive.value = false
		router.push(getMenuOrTemplateLink(item))
	}
	// 하위 메뉴 있을 경우 메뉴 열리기만 함
}

const moveTo = async () => {
	if (!authStore.loggedIn) {
		await router.push({ name: RoutesConstants.AUTH_LOGIN })
	} else if (authStore.loggedIn && authStore.passwordEntered === false) {
		await router.push({ name: RoutesConstants.MY_INDEX })
	} else {
		await router.push({ name: RoutesConstants.MY_MEMBER_INDEX })
	}
}
const moveToMyPage = () => {
	moveTo()
}
const moveToLogin = () => {
	router.push({ name: RoutesConstants.AUTH_LOGIN })
}
const moveToNotice = () => {
	router.push({ name: RoutesConstants.NOTICE_INDEX })
}
const moveToSignup = () => {
	router.push({ name: RoutesConstants.AUTH_SIGNUP_INDEX })
}

const go = (key: string) => {
	router.push({ name: key })
}
</script>

<style lang="scss" scoped>
.mobile-menu__header {
	.right-area {
		display: flex;
		gap: 26px;
	}
}
.accordion-basic {
	:deep(.tab-feature) {
		p {
			display: block;
			width: 100%;
		}
	}
	:deep(.tab-apps) {
		p {
			display: block;
			width: 100%;
		}
	}
}
.bottom-btns {
	position: absolute;
	bottom: 20px;
	width: 93%;
	margin: 0 16px;
}
.header__wrap {
	.right-area {
		button {
			&.active {
				color: var(--Primary-600);
				font-weight: 800;
			}
		}
	}
}
</style>
